import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

var firebaseConfig = {
  apiKey: 'AIzaSyAPnoPIEIKhG-lPUBrRVlqDVS2RQIZs1-Q',
  authDomain: 'izukingclass.firebaseapp.com',
  projectId: 'izukingclass',
  storageBucket: 'izukingclass.appspot.com',
  messagingSenderId: '562747208312',
  appId: '1:562747208312:web:89d4889c24dc021051338d',
  measurementId: 'G-1FQZV17QZ4',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
export const auth = firebase.auth()

// sign in with google pop up
export const googleProvider = new firebase.auth.GoogleAuthProvider()
googleProvider.addScope('https://www.googleapis.com/auth/plus.login')

// sign in with email and password
// inntialize firestore for data storage
export const db = firebase.firestore()

export const generateUserDocument = async (user: any, name: string) => {
  if (!user) {
    return
  } else {
    if (name === 'codeants.js@gmail.com') {
      db.collection('users').doc('Admin').set(user)
    } else {
      db.collection('users').doc(`student_${name}`).set(user)
    }
  }
}
