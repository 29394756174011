import React, { useState, useEffect} from 'react'
import Data from './Assets/Data'
import { db } from '../firebase'

type props = {
  isDark: boolean
}
export default function AdminPage({ isDark }: props) {
  const EditPages = () => {
    return (
      <section>
        <h1>Welcome Admin</h1>
        <h3>What do you what to do sir</h3>{' '}
        <div className='editbtn'>
          <div>
            <button>Edit Home</button>
            <button>Edit Login</button>
          </div>
          <div>
            <button>Edit class</button>
            <button>Edit SignUp</button>{' '}
          </div>
        </div>{' '}
      </section>
    )
  }

  const Students = () => {
    const ex_students = Data.students

    const [heading, setHeading] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const [data, setData] = useState<any[]>([])

  
    useEffect(() => {
      let ignore = false
      const fetchData = async () => {
        let myArr: any[] = []
        await db
          .collection('users')
          .where('email', '!=', 'codeants.js@gmail.com')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => myArr.push(doc.data()))
          })
        if (!ignore) setData(myArr)
      }
      fetchData()
      return () => {
        ignore = true
      }
    }, [data])


    const handleClick = (event: any) => {
      let studentClicked =
        event.currentTarget.previousSibling.lastChild.firstChild.innerHTML
      let status = event.currentTarget.previousSibling.lastChild.firstChild.id
      setIsOpen(true)
      setHeading(studentClicked)
      if (status === 'active') {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
    }
    return (
      <section>
        <div className='studentWrap'>
          <div className='ex_students'>
            <h3>List of ex_students</h3>
            <ol>
              {ex_students.map((student) => (
                <li className='card' id={`${student.id}`}>
                  <div className='studentProfileWrap'>
                    <div className='studentProfile'>
                      <i className='fas fa-user-circle fa-2x' />
                      <div className='studentName'>
                        <h4>{student.name}</h4>
                        <p>
                          <small>{student.course}</small>
                        </p>
                      </div>
                    </div>
                    <span
                      style={{
                        color: `${isDark ? '#00ffb3' : '#0000ff'}`,
                        cursor: 'pointer',
                      }}
                      onClick={handleClick}
                    >
                      details
                    </span>
                  </div>
                </li>
              ))}
            </ol>
          </div>
         
          <div className='active_students'>
            <h3>Active Students</h3>
            <ol>
              {data.map((student) => (
                <li className='card' id={`${student.uid}`}>
                  <div className='studentProfileWrap'>
                    <div className='studentProfile'>
                      <i className='fas fa-user-circle fa-2x' />
                      <div className='studentName'>
                        <h4 id='active'>{student.displayName}</h4>
                        <p>
                          <small>{student.course}</small>
                        </p>
                      </div>
                    </div>
                    <span
                      style={{
                        color: `${isDark ? '#00ffb3' : '#0000ff'}`,
                        cursor: 'pointer',
                      }}
                      onClick={handleClick}
                    >
                      details
                    </span>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>

        {isOpen && (
          <section style={{ display: 'grid', placeItems: 'center' }}>
            <div
              className='studentModal'
              onPointerLeave={() => setIsOpen(false)}
            >
              {isActive
                ? data
                    .filter((course) => course.displayName === heading)
                    .map((student) => (
                      <div>
                        <h1>{heading}</h1>
                        <ol>
                          <li>Email: {student.email}</li>
                          <li>Level of education: {student.education}</li>
                          <li>phone: {student.phoneNumber}</li>
                          <li>course: {student.course}</li>
                          <li>status: Active student</li>
                        </ol>
                      </div>
                    ))
                : ex_students
                    .filter((course) => course.name === heading)
                    .map((student) => (
                      <div>
                        <h1>{heading}</h1>
                        <ol>
                          <li>From: {student.state} state</li>
                          <li>Course: {student.course} Department.</li>
                          <li>phone: {student.phone}</li>
                          <li>status: Ex-student</li>
                        </ol>
                      </div>
                    ))}
            </div>
          </section>
        )}
      </section>
    )
  }

  return (
    <div className='mainWraper'>
      <EditPages />
      <Students />
    </div>
  )
}
