import React, { useEffect, useState } from 'react'
import { Link, Redirect, RouteComponentProps } from 'react-router-dom'
import { auth, googleProvider } from '../firebase'

type InputEvent = React.ChangeEvent<HTMLInputElement>
type FormEvent = React.MouseEvent<HTMLFormElement>

interface Props extends RouteComponentProps {}

export default function LogIn(props: Props) {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [password, setPassword] = useState('')
  const [messageType, setMessageType] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    alertMessage(false, 'Please Log in to gain access')
  }, [])

  const loginWithGoogle = async () => {
    if (auth.currentUser) {
      return
    } else {
      try {
        await auth.signInWithPopup(googleProvider).then(() => {
          alertMessage(true, 'Successfully Logged in')
        })
      } catch (error) {
        alertMessage(false, `${error.message}`)
        setTimeout(() => {
          alertMessage(false, `Please Try again!`)
        }, 3000)
        auth.signOut()
      }
    }
  }

  const loginWithEmailAndPassword = async (event: FormEvent) => {
    event.preventDefault()
    try {
      await auth.signInWithEmailAndPassword(email, password).then(() => {
        alertMessage(true, 'Successfully Logged in')
      })
    } catch (error) {
      alertMessage(false, `${error.message}`)
      setTimeout(() => {
        alertMessage(false, `Please Try again!`)
      }, 3000)
    }
  }

  const onChangeHandler = (event: InputEvent) => {
    const { name, value } = event.currentTarget

    if (name === 'email') {
      setEmail(value)
    } else if (name === 'password') {
      setPassword(value)
    }
  }

  const alertMessage = (type: boolean, text: string) => {
    type ? setMessageType(true) : setMessageType(false)
    setMessage(`${text}`)
    setTimeout(() => {
      setMessage('')
    }, 3000)
  }

  let { from } = (props.location.state as any) || {
    from: { pathname: '/' },
  }
  let wholog = from.pathname

  if (wholog === '/') {
    return <Redirect to={(from = '/lesson')} />
  } else if (auth.currentUser) {
    if (auth.currentUser?.email === 'admin@gmail.com') {
      return <Redirect to='/admin' />
    }
    return <Redirect to={from} />
  }

  return (
    <div className='mainWraper'>
      <form className='login' onSubmit={loginWithEmailAndPassword}>
        <h3
          style={{
            maxWidth: '80%',
            color: messageType ? 'green' : 'brown',
          }}
        >
          {message}
        </h3>
        <input
          type='email'
          id='email'
          name='email'
          placeholder='E.g: Example123@gmail.com'
          value={email}
          onChange={onChangeHandler}
          required
        />

        <input
          type={showPassword ? 'text' : 'password'}
          id='password'
          name='password'
          placeholder='Password'
          value={password}
          onChange={onChangeHandler}
          required
        />
        <span
          className='toglePassword'
          onClick={() => {
            setShowPassword(!showPassword)
            setTimeout(() => {
              setShowPassword(false)
            }, 500)
          }}
        >
          {showPassword ? (
            <i className='fas fa-eye' />
          ) : (
            <i className='fas fa-eye-slash' />
          )}
        </span>

        <button type='submit'>Log in</button>
        <p style={{ fontStyle: 'italic', margin: 0 }}>or</p>
        <button type='button' onClick={loginWithGoogle} className='google'>
          Sign in with Google
        </button>
        <p style={{ fontStyle: 'italic', margin: 0 }}>
          Don't have account? <Link to='/signUp'> Sign up here</Link>
        </p>
        <Link to='/passwordReset' style={{ fontStyle: 'italic' }}>
          Forgot password?
        </Link>
      </form>
    </div>
  )
}
