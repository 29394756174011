import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { auth } from '../firebase'

type FormEvent = React.MouseEvent<HTMLFormElement>

export default function PasswordReset() {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState('')

  const sendResetEmail = (event: FormEvent) => {
    event.preventDefault()
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setEmailSent(true)
        setError('An email has been sent to you!')
      })
      .catch(() => {
        setEmailSent(true)
        setError('Error resetting password')
      })

    setTimeout(() => {
      setEmailSent(false)
    }, 4000)
  }
  return (
    <div className='mainWraper'>
      <form className='login' onSubmit={sendResetEmail}>
        <h1>Reset your Password</h1>
        <div>
          {emailSent && <div className='error'>{error}</div>}
          <input
            type='email'
            name='userEmail'
            id='userEmail'
            value={email}
            placeholder='Input your email'
            onChange={(event) => setEmail(event.currentTarget.value)}
            style={{ width: '100%' }}
            required
          />
          <button type='submit' style={{ width: '100%', margin: '1rem auto' }}>
            Send me a reset link
          </button>
        </div>
        <Link to='/login'>&larr; back to sign in page</Link>
      </form>
    </div>
  )
}
