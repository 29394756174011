import React, { useState } from 'react'
import Data from './Assets/Data'

export default function Projects() {
  const projects = Data.projects
  const [filterProject, setFilterProject] = useState('All')

  return (
    <div className='mainWraper'>
      <section>
        <h1>Projects</h1>
        <p className=''>
          <b>Select Projects: </b>

          <select
            value={filterProject}
            onChange={(e) => setFilterProject(e.currentTarget.value)}
          >
            {['All', 'Basic Js', 'ReactJs', 'Others'].map(
              (option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              )
            )}
          </select>
        </p>
        <div className='projectWrap'>
          {filterProject === 'All'
            ? projects.map((project) => (
                <div className='card' id={`${project.id}`}>
                  <div className='projectImgWrap'>
                    <img src={project.image} alt={project.name} />
                  </div>
                  <a href={project.link} className='preview'>
                    <i className='fas fa-external-link-alt fa-2x'></i>
                  </a>
                  <div className='projectTextWrap'>
                    <h3>{project.name}</h3>
                    <p>
                      <small>{project.description}</small>
                    </p>
                    <span>buildBy: {project.buildBy}</span>
                    <div className='links'>
                      <a href={project.source}>
                        {' '}
                        <i className='fab fa-github' />
                      </a>
                      <small>
                        <i
                          className='fas fa-heart'
                          style={{ color: '#f14444' }}
                        />
                        {''}
                        {project.likes}
                      </small>
                      <a href={project.link}>Explore</a>
                    </div>
                  </div>
                </div>
              ))
            : projects
                .filter((item) => item.class === filterProject)
                .map((project) => (
                  <div className='card' id={`${project.id}`}>
                    <div className='projectImgWrap'>
                      <img src={project.image} alt={project.name} />
                    </div>
                    <a href={project.link} className='preview'>
                      <i className='fas fa-external-link-alt fa-2x'></i>
                    </a>
                    <div className='projectTextWrap'>
                      <h3>{project.name}</h3>
                      <p>
                        <small>{project.description}</small>
                      </p>
                      <span>buildBy: {project.buildBy}</span>
                      <div className='links'>
                        <a href={project.source}>
                          {' '}
                          <i className='fab fa-github' />
                        </a>
                        <small>
                          <i
                            className='fas fa-heart'
                            style={{ color: '#f14444' }}
                          />
                          {''}
                          {project.likes}
                        </small>
                        <a href={project.link}>Explore</a>
                      </div>
                    </div>
                  </div>
                ))}
        </div>
      </section>
    </div>
  )
}
