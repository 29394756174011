import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { auth, generateUserDocument, googleProvider } from '../firebase'
import Data from './Assets/Data'

export default function SigUp() {
  type InputEvent = React.ChangeEvent<HTMLInputElement>
  type FormEvent = React.MouseEvent<HTMLFormElement>
  type SelectEvent = React.ChangeEvent<HTMLSelectElement>
  const [course, education, state, nation] = [
    Data.subjects.map((subject) => subject.name),
    Data.certificate,
    Data.state,
    Data.nation,
  ]

  const [messageType, setMessageType] = useState(false)
  const [message, setMessage] = useState('')
  const [isSignedUp, setIsSignedUp] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [value, setValue] = useState({
    displayName: '',
    email: '',
    phoneNumber: '',
    photoURL: '',
    department: '',
    education: '',
    password: '',
    selectedState: '',
    selectedNation: '',
  })

  const onChangeHandler = (event: InputEvent | SelectEvent): void => {
    setValue({ ...value, [event.target.name]: event.target.value })
  }

  const registerUserWithGoogle = async () => {
    const { department, education, selectedState, selectedNation } = value
    if (auth.currentUser) {
      return
    } else {
      try {
        await auth.signInWithPopup(googleProvider).then((result) => {
          const user = {
            uid: result.user?.uid,
            displayName: result.user?.displayName,
            email: result.user?.email,
            phoneNumber: result.user?.phoneNumber,
            photoURL: result.user?.photoURL,
            department,
            education,
            selectedState,
            selectedNation,
          }
          generateUserDocument(user, `${user.email}`)
          alertMessage(true, `${user?.displayName} Successfully Logged in`)
          setTimeout(() => {
            setIsSignedUp(true)
          }, 2000)
        })
      } catch (error) {
        alertMessage(false, `${error.message}`)
        setTimeout(() => {
          alertMessage(false, `Please Try again!`)
          setIsSignedUp(false)
        }, 3000)
      }
    }
  }

  const registerUser = async (event: FormEvent) => {
    event.preventDefault()
    const {
      displayName,
      email,
      password,
      phoneNumber,
      department,
      education,
      selectedState,
      selectedNation,
    } = value
    try {
      await auth
        .createUserWithEmailAndPassword(email, password)
        .then((result) => {
          const user = {
            uid: result.user?.uid,
            displayName,
            email,
            phoneNumber,
            photoURL: result.user?.photoURL,
            department,
            education,
            selectedState,
            selectedNation,
          }
          generateUserDocument(user, `${user.email}`)
          alertMessage(
            true,
            `successfully added user with email:${email}${displayName} and username of ${displayName}`
          )
          setTimeout(() => {
            setIsSignedUp(true)
          }, 2000)
        })
    } catch (error) {
      alertMessage(false, `${error.message}`)
      setTimeout(() => {
        alertMessage(false, `Please Try again!`)
        setIsSignedUp(false)
      }, 3000)
    }
  }

  const alertMessage = (type: boolean, text: string) => {
    type ? setMessageType(true) : setMessageType(false)
    setMessage(`${text}`)
    setTimeout(() => {
      setMessage('')
    }, 3000)
  }

  if (isSignedUp || auth.currentUser) {
    if (auth.currentUser?.email === 'admin@gmail.com') {
      return <Redirect to='/admin' />
    }
    return <Redirect to='/lesson' />
  } else {
    return (
      <div className='mainWraper'>
        <form className='signup' onSubmit={registerUser}>
          <div className='imgWrapper'>
            <i className='fas fa-user-circle fa-5x' />
          </div>
          <p
            style={{
              margin: 0,
              marginBottom: '1rem',
              maxWidth: '80%',
              color: messageType ? 'green' : 'brown',
            }}
          >
            {message}
          </p>
          <div className='inputWrapper'>
            <div>
              <input
                type='text'
                name='displayName'
                placeholder='displayName'
                onChange={onChangeHandler}
                required
              />
              <input
                type='number'
                name='phoneNumber'
                placeholder='Phone Number'
                onChange={onChangeHandler}
                required
              />
              <p className='genderWrap'>
                <b>Gender: </b>

                <select name='gender' onChange={onChangeHandler}>
                  {['Male', 'Female'].map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </p>
            </div>
            <div>
              <input
                type='text'
                name='email'
                placeholder='Email Address'
                onChange={onChangeHandler}
                required
              />
              <input
                type={showPassword ? 'text' : 'password'}
                name='password'
                placeholder='Create Password'
                onChange={onChangeHandler}
                required
              />
              <span
                className='togleSignupPassword'
                onClick={() => {
                  setShowPassword(!showPassword)
                  setTimeout(() => {
                    setShowPassword(false)
                  }, 500)
                }}
              >
                {showPassword ? (
                  <i className='fas fa-eye' />
                ) : (
                  <i className='fas fa-eye-slash' />
                )}
              </span>
              <p className='genderWrap courseWrap'>
                <b>Department:</b>

                <select name='department' onChange={onChangeHandler}>
                  {course.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </p>
            </div>
          </div>
          <div className='selectWrapper'>
            <span>
              <p style={{ margin: 0 }}>
                <b>Education :</b>
              </p>
              <select name='education' onChange={onChangeHandler}>
                {education.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <p style={{ margin: 0 }}>
                <b>State:</b>{' '}
              </p>
              <select name='selectedState' onChange={onChangeHandler}>
                {state.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <p style={{ margin: 0 }}>
                <b>Nation :</b>{' '}
              </p>
              <select name='selectedNation' onChange={onChangeHandler}>
                {nation.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </span>
          </div>
          <div className='btnWrapper'>
            <button type='submit'>Sign Up</button>
            <p style={{ fontStyle: 'italic', margin: 0 }}>or</p>
            <button
              type='button'
              className='google'
              onClick={registerUserWithGoogle}
            >
              Sign up with Google
            </button>
            <p style={{ fontStyle: 'italic', margin: 0 }}>
              Have account? <Link to='/login'> LogIn</Link>
            </p>
          </div>
        </form>
      </div>
    )
  }
}
