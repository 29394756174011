import React, { useState } from 'react'
import Data from './Assets/Data'

const UserProfilePage = () => {
  const userPageContext = Data.userPage
  const [isOpen, setIsOpen] = useState(false)
  const [heading, setHeading] = useState('')

  const ShowCase = () => {
    return (
      <section className='userShowCase'>
        <h1>Welcome!</h1>
        <p>{userPageContext.introText}</p>
      </section>
    )
  }

  const handleClick = (event) => {
    let cousreClicked = event.currentTarget.firstChild.innerHTML
    setIsOpen(true)
    setHeading(cousreClicked)
  }
  const Course = () => {
    return (
      <section>
        <h1>Course Outlines</h1>
        <div className='userCourseWrap'>
          {userPageContext.course.map((course) => {
            return (
              <div className='card' onClick={handleClick}>
                <h3>{course.name}</h3>
                <div>
                  {course.outlines.map((outline) => {
                    return (
                      <ol>
                        <li>{outline.name}</li>
                        {/* <p>{outline.description}</p> */}
                      </ol>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </section>
    )
  }
  const Lesson = () => {
    return (
      <div className='modal'>
        <h1>
          <i
            onClick={() => setIsOpen(!isOpen)}
            className='fas fa-arrow-circle-left back'
          />{' '}
          {heading}
        </h1>
        <div>
          {userPageContext.course
            .filter((course) => course.name === heading)
            .map((item) => {
              return (
                <React.Fragment>
                  <p>{item.intro}</p>
                  <h2>
                    Skills <small>you need to become {item.name}</small>{' '}
                  </h2>
                  <p>
                    <ol>
                      {item.outlines.map((list) => {
                        return (
                          <li>
                            <a href={list.link}>
                              <b>{list.name}: </b>{' '}
                            </a>
                            <small>{list.description}....</small>
                            <a href={list.link}>
                              {' '}
                              Start learning {list.name} here
                            </a>
                          </li>
                        )
                      })}
                    </ol>
                  </p>
                </React.Fragment>
              )
            })}
        </div>
      </div>
    )
  }
  return (
    <div className='mainWraper'>
      {isOpen ? (
        <Lesson />
      ) : (
        <React.Fragment>
          <ShowCase />
          <Course />
        </React.Fragment>
      )}
    </div>
  )
}

export default UserProfilePage
