import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Data from './Assets/Data'
import Footer from '../Footer'
import { db } from '../firebase'

type FormEvent = React.MouseEvent<HTMLFormElement>
type props = {
  isDark: boolean
}

export default function HomeComponents({ isDark }: props) {
  const Intro = () => {
    const [text, setText] = useState('')

    useEffect(() => {
      var txt = `Here we listen, inspire, instructs and mentor you to fasten your path
                  in achieving that your great dream. this site will serve as your hand
                  book, where you are advive to always look up for your journey to
                  become a Self-thought UI/UX designer, FrontEnd, BackEnd, Full Stack or a
                    Mobile Software Developer.
                  `
      var i = 0
      var result = ''
      function typeWriter() {
        if (i < txt.length) {
          result += txt.charAt(i)
          i++
          setTimeout(typeWriter, 50)
        }
        setText(result)
      }
      typeWriter()
    }, [])

    return (
      <section
        className='intro'
        style={{ backgroundColor: `${isDark ? '#5a5959' : 'lightblue'} ` }}
      >
        <h1>Welcome</h1>
        <h3 style={{ color: `${isDark ? '#00ffb3' : '#1c00bb'} ` }}>
          To <br />
          Izuking Developer's Class
        </h3>
        <p>{text} &#9997;</p>
      </section>
    )
  }

  const RoadMap = () => {
    const courses = Data.subjects

    return (
      <section>
        <h1>Departments</h1>
        <p className='descript'>
          Sign up for free to get enrolled to any of the department
        </p>
        <div className='courseWrap'>
          {courses.map((course) => (
            <div className='card' id={`${course.id}`}>
              <h3>{course.name}</h3>
              <small>
                <em>{course.duration} Course</em>
              </small>
              <b className='coursework'>Coursework</b>
              <ul>
                {course.outlines.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
              <Link to='/signUp' className='Link'>
                signup
              </Link>
            </div>
          ))}
        </div>
      </section>
    )
  }

  const Projects = () => {
    const projects = Data.projects

    return (
      <section>
        <h1>Projects</h1>
        <div className='projectWrap'>
          {projects
            .filter((item) => item.class === 'ReactJs')
            .map((project) => (
              <div className='card' id={`${project.id}`}>
                <div className='projectImgWrap'>
                  <img src={project.image} alt={project.name} />
                </div>
                <a href={project.link} className='preview'>
                  <i className='fas fa-external-link-alt fa-2x'></i>
                </a>
                <div className='projectTextWrap'>
                  <h3>{project.name}</h3>
                  <p>
                    <small>{project.description}</small>
                  </p>
                  <span>buildBy: {project.buildBy}</span>
                  <div className='links'>
                    <a href={project.source}>
                      {' '}
                      <i className='fab fa-github' />
                    </a>
                    <small>
                      <i
                        className='fas fa-heart'
                        style={{ color: '#f14444' }}
                      />
                      {''}
                      {project.likes}
                    </small>
                    <a href={project.link}>Explore</a>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className='morePro'>
          <Link to='/projects' className='Link'>
            more projects
          </Link>
        </div>
      </section>
    )
  }

  const Comments = () => {
    const [name, setName] = useState('')
    const [message, setMessage] = useState('')
    const [data, setData] = useState<any[]>([])
    const [IsClicked, setIsClicked] = useState(false)

    let listener = useCallback(
      () => db.collection('comments').onSnapshot((doc) => doc.docChanges()),
      []
    )

    useEffect(() => {
      let ignore = false
      const fetchData = async () => {
        let myArr: any[] = []
        await db
          .collection('comments')
          .get()
          .then((comment) => {
            comment.forEach((doc) => myArr.push(doc.data()))
          })
        if (!ignore) setData(myArr)
      }
      fetchData()
      return () => {
        ignore = true
      }
    }, [data, listener])

    const createReply = (event: FormEvent) => {
      event?.preventDefault()
      let createdAt = new Date().toLocaleString()
      let createdId = new Date().getTime()
      setData([
        ...data,
        { id: createdId, name: name, message: message, createdAt: createdAt },
      ])
      db.collection('comments').add({
        id: createdId,
        name: name,
        message: message,
        createdAt: createdAt,
      })
      setName('')
      setMessage('')
      setIsClicked(false)
    }

    return (
      <section>
        <h1>Comments</h1>
        <div className='commentsWrap'>
          {data.map((comment) => (
            <div className='card' id={comment.id}>
              <div className='heading'>
                <div className='profileWrap'>
                  <div className='profile'>
                    <i className='fas fa-user-circle commentAvatar' />
                    <h4 className='profileName'>{comment.name} </h4>
                    {''}
                    <b className='profileSay'> Says:</b>
                  </div>
                  <span className='createdAt'>{comment.createdAt}</span>
                </div>
                <b className='profileReply'>
                  reply{' '}
                  <i
                    className='fas fa-reply'
                    style={{ color: `${isDark ? 'white' : '#333'}` }}
                  />
                </b>
              </div>
              <p className='message'>{comment.message}</p>
            </div>
          ))}
        </div>
        <div className='replyFormWrap'>
          <p style={{ margin: 0 }}> Leave a Reply</p>
          <form
            onSubmit={createReply}
            className={`${IsClicked ? 'replyFormX' : 'replyForm'}`}
          >
            <input
              required
              type='text'
              placeholder=' Name'
              value={name}
              onFocus={() => setIsClicked(true)}
              onBlur={() => setIsClicked(false)}
              onChange={(e) => setName(e.currentTarget.value)}
            />
            <textarea
              required
              name='message'
              placeholder='Enter your message'
              value={message}
              onFocus={() => setIsClicked(true)}
              onBlur={() => setIsClicked(false)}
              onChange={(e) => setMessage(e.currentTarget.value)}
            />
            <button type='submit' onFocus={() => setIsClicked(true)}>
              Submit
            </button>
          </form>
        </div>
      </section>
    )
  }

  return (
    <React.Fragment>
      <div className='mainWraper' id='top'>
        <Intro />
        <RoadMap />
        <div className='separator'></div>
        <Projects />
        <div className='separator'></div>
        <Comments />
        <div className='separator'></div>
      </div>
      <Footer isDark={isDark} top='#top' />
    </React.Fragment>
  )
}
