import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import { auth } from './firebase'

type props = {
  userName: string
  avatar: string
  email: string
  isDark: boolean
  myTheme: () => void
}

export default function NavBar({
  userName,
  avatar,
  email,
  isDark,
  myTheme,
}: props) {
  const AuthButton = withRouter(({ history }) =>
    auth.currentUser ? (
      <span
        onClick={() => {
          if (window.confirm('Do you want to Log Out?')) {
            auth.signOut()
            setTimeout(() => {
              history.push('/')
              localStorage.clear()
            }, 50)
          } else {
            return
          }
        }}
      >
        LogOut
      </span>
    ) : (
      <span>LogIn</span>
    )
  )

  return (
    <div
      className='Navbar'
      style={{ backgroundColor: `${isDark ? '#1E1E1E' : '#dadada'}` }}
    >
      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        {avatar === '' ? (
          <i className='fas fa-user-circle fa-2x avatar' />
        ) : (
          <img src={avatar} alt={'avatar'} className='userAvatar' />
        )}
        <div>
          {userName === '' ? (
            <b
              style={{
                fontSize: 18,
                color: `${isDark ? 'Gold' : '#011436'}`,
              }}
            >
              Hello User
            </b>
          ) : (
            <b>{userName}</b>
          )}

          {<p style={{ margin: 0, fontSize: 10 }}>{email}</p>}
        </div>
      </div>

      <nav>
        <ul>
          <li>
            <Link to='/' style={{ color: `${isDark ? '#00ffb3' : ''}` }}>
              <i className='fa fa-home'></i>
            </Link>
          </li>
          <li>
            <Link to='/lesson' style={{ color: `${isDark ? '#00ffb3' : ''}` }}>
              Class
            </Link>
          </li>
          <li>
            <Link to='/logIn' style={{ color: `${isDark ? '#00ffb3' : ''}` }}>
              <AuthButton />
            </Link>
          </li>
          <li
            onClick={myTheme}
            style={{
              cursor: 'pointer',
              color: `${isDark ? 'white' : 'black'}`,
            }}
          >
            <i className={isDark ? 'fa fa-sun' : 'fa fa-moon'} />{' '}
            <span className='themeBtn'>{isDark ? 'Light' : 'Dark'}</span>Theme
          </li>
        </ul>
      </nav>
      <div className={isDark ? 'social socialColor' : 'social'}>
        <ul>
          <li>
            <a href='https://github.com/MrVal042'>
              <i className='fab fa-github fa-2x' />
            </a>
          </li>
          <li>
            <span
              onClick={() =>
                window.open(
                  'mailto:codeants.js@gmail.com?subject=subject&body=body'
                )
              }
              style={{ color: `${isDark ? '' : '#0087AD'}` }}
            >
              <i className='far fa-envelope fa-2x' />
            </span>
          </li>
          <li>
            <a href='https://www.linkedin.com/in/valentine-asogwa-030272212/'>
              <i className='fab fa-linkedin fa-2x' />
            </a>
          </li>
          <li>
            <a href='https://web.facebook.com/profile.php?id=100004927487683'>
              <i className='fab fa-facebook fa-2x' />
            </a>
          </li>
          <li>
            <a href='https://www.instagram.com/mr_val_d/'>
              <i className='fab fa-instagram fa-2x' />
            </a>
          </li>
          <li>
            <a href='https://wa.me/+2347065208250/?text=Hi%20!%0AThank%20you%20for%20visiting%20IzukingClass%20👍.%0APlease%20type%20your%20name,%20message%20and%20send.'>
              <i className='fab fa-whatsapp fa-2x' />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
