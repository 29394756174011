type props = {
  top: string
  isDark: boolean
}

export default function Footer({ isDark, top }: props) {
  return (
    <footer
      style={{
        backgroundColor: `${isDark ? '#1E1E1E' : '#dadada'}`,
        color: `${isDark ? 'white' : ''}`,
      }}
    >
      <p>
        {' '}
        <b>IzukingClass</b> All Right Reserved
      </p>
      <p style={{ margin: 0, fontStyle: 'italic' }}>
        built & designedBy{' '}
        <b>
          <a href='https://github.com/MrVal042'>MrValO42</a>{' '}
        </b>
      </p>
      <p>copyright &copy; {new Date().getFullYear()}</p>
      <a
        href={top}
        style={{
          position: 'relative',
          justifySelf: 'end',
          justifyItems: 'self-start',
          top: -70,
          display: 'flex',
          flexDirection: 'column',
          placeItems: 'center',
        }}
      >
        <i
          className='fas fa-arrow-up fa-2x'
          style={{
            color: 'brown',
          }}
        />
        <span>back to top</span>
      </a>
    </footer>
  )
}
