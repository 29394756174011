import * as React from 'react'
import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import './App.css'

import NavBar from './NavBar'
import LogIn from './components/LogIn'
import { auth } from './firebase'
import SignUp from './components/SignUp'
import HomePage from './components/HomeComponents'
import AdminPage from './components/AdminPage'
import UserPage from './components/UserPage'
import PasswordReset from './components/PasswordReset'
import PageNotFound from './components/PageNotFound'
import ProjectsPage from './components/ProjectsPage'

export default function App() {
  const [avatar, setAvatar] = useState('')
  const [email, setEmail] = useState('')
  const [isDark, setIsDark] = useState(false)
  const [userName, setUserName] = useState('')
  
  const myTheme = () => {
    setIsDark(!isDark)
  }
  
  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        let currentUser = authUser.displayName
        let photoURL = authUser.photoURL
        setEmail(`${authUser.email}`)
        if (currentUser === null) {
          if (authUser.email === 'admin@gmail.com') {
            return setUserName(`Hello Admin`)
          }
          return setUserName(`Hello User`)
        } else {
          setUserName(`${currentUser}`)
        }
        if (photoURL === null) {
          return
        } else {
          setAvatar(`${photoURL}`)
        }
        return authUser
      }
    })
  }, [])
  
  const Admin = () => {
    return (
      <React.Fragment>
        <NavBar
          isDark={isDark}
          myTheme={myTheme}
          userName='Hello Admin'
          avatar={avatar}
          email={email}
          />
        <AdminPage isDark={isDark} />
      </React.Fragment>
    )
  }
  const Home = () => {
    return (
      <React.Fragment>
        <NavBar
          isDark={isDark}
          myTheme={myTheme}
          userName='Izuking'
          avatar={'./img/izuking_logo.jpeg'}
          email={''}
          />
        <HomePage isDark={isDark}  />
      </React.Fragment>
    )
  }
  const Class = () => {
    return (
      <React.Fragment>
        <NavBar
          isDark={isDark}
          myTheme={myTheme}
          userName={userName}
          avatar={avatar}
          email={email}
        />
        <UserPage />
      </React.Fragment>
    )
  }
  const Projects = () => {
    return (
      <React.Fragment>
        <NavBar
          isDark={isDark}
          myTheme={myTheme}
          userName={userName}
          avatar={avatar}
          email={email}
        />
        <ProjectsPage />
      </React.Fragment>
    )
  }
  return (
    <Router basename='/'>
      <div className={isDark ? 'darkTheme' : 'lightTheme'}>
        <Route exact path='/' component={Home} />
        <Route path='/login' component={LogIn} />
        <Route path='/signup' component={SignUp} />
        <Route path='/passwordReset' component={PasswordReset} />
        <Route path='/projects' component={Projects} />
        <Route path='/pageNotFound' component={PageNotFound} />
        <PrivateRoute path='/admin' component={Admin} />
        <PrivateRoute path='/lesson' component={Class} />
      </div>
    </Router>
  )
}

const PrivateRoute: React.ComponentType<any> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}
