import React, { useEffect, useState } from 'react'

export default function PageNotFound() {
  const [text, setText] = useState('')
  
  useEffect(() => {
    var txt = `Awwww...........Don't Cry.
    What you're looking for may have been misplaced in Long Term Memory.`
    var result = ''
    var i = 0

    function typeWriter() {
      if (i < txt.length) {
        result += txt.charAt(i)
        i++
        setTimeout(typeWriter, 50)
      }
      setText(result)
    }
    typeWriter()
  }, [])

  return (
    <div className='mainWraper'>
      <h1>Oops ! page not found</h1>
      <div className='pageWrap'>
        <div className='imgWrap'>
          <img src='./img/pageNotFound.jpeg' alt='Cry' />
        </div>
        <div className='textWrap'>
          <p>{text} &#9997;</p>
          <a href='/'>go back home</a>
        </div>
      </div>
    </div>
  )
}

